import React, { FC, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router';
import { isMobile, isDesktop } from 'react-device-detect';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { I18nText } from 'src/components/i18n';
import { MenuSelect } from 'src/components/selects';
import { userVar } from 'src/apollo/cache/cache';
import * as ROUTES from 'src/utils/constants/routes';
import { AUTH_TOKEN } from 'src/utils/constants';
import { deleteCookie } from 'src/utils/helpers';
import { useGetMyVesselsQuery } from 'src/gql';
import { COMPANY_ROLES, UserRole } from 'src/utils/constants/roles';
import { ROUTES_FOR_ROLES } from 'src/App.routes';
import { checkCompanyAccount, checkRouteAvailable } from 'src/utils/helpers/roles';
import { LanguagePopup } from 'src/components/popups/LanguagePopup/LanguagePopup';

export const HEADER_TYPES = {
  ORDERS: 'orders',
  ORDER_STEPS: 'orderSteps',
  SHIPOWNER_NAV: 'shipwownerNavigation',
  SHIPOWNER_ORDER: 'shipwownerOrder',
  SHIPOWNER_OFFER: 'shipownerOffer',
};

interface HeaderProps {
  type: 'orders' | 'orderSteps' | 'shipwownerNavigation' | 'shipwownerOrder' | 'shipownerOffer';
  stepNumber?: number;
}

export const Header: FC<HeaderProps> = ({ type, stepNumber = 1 }) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();
  const location = useLocation();
  const [showActions, setShowActions] = useState(false);
  const [showLocalePopup, setShowLocalePopup] = useState(false);
  // const vesselCount = useReactiveVar(vesselCountVar);

  const companyAcc = !!user.roles && checkCompanyAccount(user.roles);
  const { data: vesselsData } = useGetMyVesselsQuery({
    // fetchPolicy: 'Series',
    variables: { limit: 100, offset: 0 },
    skip: typeof user?.isAdmin === 'boolean' && companyAcc,
  });

  const displayName = useMemo(
    () => `${user.firstName} ${user.lastName && user.lastName[0].toUpperCase() + '.'}`,
    [user.id],
  );

  const userHighestPrioRole = useMemo(
    () => COMPANY_ROLES.filter(el => user.roles?.includes(el.name))[0],
    [user.id],
  );

  if (isMobile && companyAcc) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container className={classes.gridItem}>
            <Grid xs={6} item>
              <div
                className={classes.titleContainer}
                onClick={() => navigate(ROUTES_FOR_ROLES[userHighestPrioRole.name][0].route)}
              >
                <div className={classes.logoMobile} />
                <div className={classes.titleWrapperMobile}>
                  <I18nText path="app.owner" />
                </div>
              </div>
            </Grid>

            <Grid xs={6} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={displayName}
                options={[
                  {
                    title: { path: 'header.summary' },
                    invisible: !checkRouteAvailable(ROUTES.SUMMARY, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.SUMMARY);
                    },
                  },
                  {
                    title: { path: 'header.map' },
                    invisible: !checkRouteAvailable(ROUTES.MAP, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.MAP);
                    },
                  },
                  {
                    title: { path: 'header.orders' },
                    invisible: !checkRouteAvailable(ROUTES.ORDERS, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.ORDERS);
                    },
                  },
                  {
                    title: { path: 'header.display' },
                    invisible: !checkRouteAvailable(ROUTES.DISPLAY, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.DISPLAY);
                    },
                  },
                  {
                    title: { path: 'header.ports' },
                    invisible: !checkRouteAvailable(ROUTES.PORTS, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.PORTS);
                    },
                  },
                  {
                    title: { path: 'header.people' },
                    invisible: !checkRouteAvailable(ROUTES.ACCOUNTS, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.ACCOUNTS);
                    },
                  },
                  {
                    title: { path: 'header.marineTraffic' },
                    invisible: !checkRouteAvailable(
                      ROUTES.MARINE_TRAFFIC,
                      userHighestPrioRole.name,
                    ),
                    onClick: () => {
                      navigate(ROUTES.MARINE_TRAFFIC);
                    },
                  },
                  {
                    title: { path: 'header.monthlyPlans' },
                    invisible: !checkRouteAvailable(
                      ROUTES.MONTHLY_PLANS,
                      userHighestPrioRole.name,
                    ),
                    onClick: () => {
                      navigate(ROUTES.MONTHLY_PLANS);
                    },
                  },
                  {
                    title: { path: 'header.language' },
                    onClick: () => {
                      setShowLocalePopup(true);
                    },
                  },
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>

        {showLocalePopup && (
          <LanguagePopup
            handleClose={() => {
              setShowLocalePopup(false);
            }}
          ></LanguagePopup>
        )}
      </div>
    );
  }

  if (isDesktop && companyAcc && type === HEADER_TYPES.ORDERS && displayName) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container className={classes.gridItem}>
            <Grid xs={2} item>
              <div
                className={classes.titleContainer}
                onClick={() => navigate(ROUTES_FOR_ROLES[userHighestPrioRole.name][0].route)}
              >
                <div className={classes.logo} />
                <div className={classes.titleWrapper}>
                  <I18nText path="app.owner" />
                </div>
              </div>
            </Grid>
            {checkRouteAvailable(ROUTES.SUMMARY, userHighestPrioRole.name) && (
              <Grid
                xs={2}
                item
                onClick={() => navigate(ROUTES.SUMMARY)}
                className={
                  location.pathname === ROUTES.SUMMARY
                    ? classes.activeNavigationContainer
                    : classes.unActiveNavigationContainer
                }
              >
                <div>
                  <I18nText path="header.summary" />
                </div>
              </Grid>
            )}
            {checkRouteAvailable(ROUTES.MAP, userHighestPrioRole.name) && (
              <Grid
                xs={2}
                item
                onClick={() => navigate(ROUTES.MAP)}
                className={
                  location.pathname === ROUTES.MAP
                    ? classes.activeNavigationContainer
                    : classes.unActiveNavigationContainer
                }
              >
                <div>
                  <I18nText path="header.map" />
                </div>
              </Grid>
            )}
            {checkRouteAvailable(ROUTES.ORDERS, userHighestPrioRole.name) && (
              <Grid
                xs={2}
                item
                onClick={() => navigate(ROUTES.ORDERS)}
                className={
                  location.pathname === ROUTES.ORDERS
                    ? classes.activeNavigationContainer
                    : classes.unActiveNavigationContainer
                }
              >
                <div>
                  <I18nText path="header.orders" />
                </div>
              </Grid>
            )}

            <Grid xs={4} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={displayName}
                options={[
                  {
                    title: { path: 'header.display' },
                    invisible: !checkRouteAvailable(ROUTES.DISPLAY, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.DISPLAY);
                    },
                  },
                  {
                    title: { path: 'header.ports' },
                    invisible: !checkRouteAvailable(ROUTES.PORTS, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.PORTS);
                    },
                  },
                  {
                    title: { path: 'header.people' },
                    invisible: !checkRouteAvailable(ROUTES.ACCOUNTS, userHighestPrioRole.name),
                    onClick: () => {
                      navigate(ROUTES.ACCOUNTS);
                    },
                  },
                  {
                    title: { path: 'header.marineTraffic' },
                    invisible: !checkRouteAvailable(
                      ROUTES.MARINE_TRAFFIC,
                      userHighestPrioRole.name,
                    ),
                    onClick: () => {
                      navigate(ROUTES.MARINE_TRAFFIC);
                    },
                  },
                  {
                    title: { path: 'header.monthlyPlans' },
                    invisible: !checkRouteAvailable(
                      ROUTES.MONTHLY_PLANS,
                      userHighestPrioRole.name,
                    ),
                    onClick: () => {
                      navigate(ROUTES.MONTHLY_PLANS);
                    },
                  },
                  {
                    title: { path: 'header.language' },
                    onClick: () => {
                      setShowLocalePopup(true);
                    },
                  },
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>

        {showLocalePopup && (
          <LanguagePopup
            handleClose={() => {
              setShowLocalePopup(false);
            }}
          ></LanguagePopup>
        )}
      </div>
    );
  }

  if (isDesktop && companyAcc && type === HEADER_TYPES.ORDER_STEPS) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container className={classes.gridItem}>
            <Grid xs={2} item>
              <div
                className={classes.titleContainer}
                onClick={() => navigate(ROUTES_FOR_ROLES[userHighestPrioRole.name][0].route)}
              >
                <div className={classes.logo} />
                <div className={classes.titleWrapper}>
                  <I18nText path="app.owner" />
                </div>
              </div>
            </Grid>
            <Grid
              xs={2}
              item
              className={
                stepNumber === 1 ? classes.stepsContainer : classes.unActiveStepsContainer
              }
            >
              <div
                className={stepNumber === 1 ? classes.stepWrapper : classes.unActiveStepWrapper}
              >
                <I18nText path="menuLabel.step" paramValues={{ stepIndex: 1 }} />
              </div>
              <div>
                <I18nText path="menuLabel.details" />
              </div>
            </Grid>
            <Grid
              xs={1}
              item
              className={
                stepNumber === 2 ? classes.stepsContainer : classes.unActiveStepsContainer
              }
            >
              <div
                className={stepNumber === 2 ? classes.stepWrapper : classes.unActiveStepWrapper}
              >
                <I18nText path="menuLabel.step" paramValues={{ stepIndex: 2 }} />
              </div>
              <div>
                <I18nText path="menuLabel.ports" />
              </div>
            </Grid>
            <Grid xs={8} item className={classes.userAccount}>
              <div className={classes.closeButton} onClick={() => navigate(ROUTES.ORDERS)} />
            </Grid>
          </Grid>
        </Container>

        {showLocalePopup && (
          <LanguagePopup
            handleClose={() => {
              setShowLocalePopup(false);
            }}
          ></LanguagePopup>
        )}
      </div>
    );
  }

  if (isMobile && user.isShipowner && user.companyName) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container alignItems="center" className={classes.gridItem}>
            <Grid
              xs={6}
              sm={6}
              item
              className={classes.titleContainer}
              onClick={() => navigate(ROUTES.SHIPOWNER_ORDERS)}
            >
              <div className={classes.logoMobile} />
              <div className={classes.titleWrapperMobile}>
                <I18nText path="app.owner" />
              </div>
            </Grid>

            <Grid xs={6} sm={6} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={user.companyName}
                options={[
                  {
                    title: { path: 'header.orders' },
                    onClick: () => {
                      navigate(ROUTES.SHIPOWNER_ORDERS);
                    },
                  },
                  {
                    title: {
                      path: 'header.vessels',
                      paramValues: { vesselCount: vesselsData?.myVessels?.total ?? 0 },
                    },
                    onClick: () => {
                      navigate(ROUTES.SHIPOWNER_VESSELS);
                    },
                  },
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  if (isDesktop && user.isShipowner && type === HEADER_TYPES.SHIPOWNER_NAV && user.companyName) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container alignItems="center" className={classes.gridItem}>
            <Grid
              xs={2}
              item
              className={classes.titleContainer}
              onClick={() => navigate(ROUTES.SHIPOWNER_ORDERS)}
            >
              <div className={classes.logo} />
              <div className={classes.titleWrapper}>
                <I18nText path="app.owner" />
              </div>
            </Grid>
            <Grid
              xs={2}
              item
              className={
                location.pathname === ROUTES.SHIPOWNER_ORDERS
                  ? classes.activeNavigationContainer
                  : classes.unActiveNavigationContainer
              }
              onClick={() => navigate(ROUTES.SHIPOWNER_ORDERS)}
            >
              <div>
                <I18nText path="header.orders" />
              </div>
            </Grid>
            <Grid
              xs={2}
              item
              className={
                location.pathname === ROUTES.SHIPOWNER_VESSELS
                  ? classes.activeNavigationContainer
                  : classes.unActiveNavigationContainer
              }
              onClick={() => navigate(ROUTES.SHIPOWNER_VESSELS)}
            >
              <div>
                <I18nText
                  path="header.vessels"
                  paramValues={{ vesselCount: vesselsData?.myVessels?.total ?? 0 }}
                />
              </div>
            </Grid>
            <Grid xs={6} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={user.companyName}
                options={[
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  if (isDesktop && user.isShipowner && type === HEADER_TYPES.SHIPOWNER_OFFER && user.companyName) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container alignItems="center" className={classes.gridItem}>
            <Grid
              xs={2}
              item
              className={classes.titleContainer}
              onClick={() => navigate(ROUTES.SHIPOWNER_ORDERS)}
            >
              <div className={classes.logo} />
              <div className={classes.titleWrapper}>
                <I18nText path="app.owner" />
              </div>
            </Grid>
            <Grid xs={4} item>
              <div>Making an offer</div>
            </Grid>

            <Grid xs={6} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={user.companyName}
                options={[
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  if (isDesktop && user.isShipowner && type === HEADER_TYPES.SHIPOWNER_ORDER && user.companyName) {
    return (
      <div className={classes.headerContainer}>
        <Container className={classes.headerWrapper}>
          <Grid container alignItems="center" className={classes.gridItem}>
            <Grid
              xs={2}
              item
              className={classes.titleContainer}
              onClick={() => navigate(ROUTES.SHIPOWNER_ORDERS)}
            >
              <div className={classes.logo} />
              <div className={classes.titleWrapper}>
                <I18nText path="app.owner" />
              </div>
            </Grid>
            <Grid xs={4} item>
              <div>Order</div>
            </Grid>

            <Grid xs={6} item className={classes.userAccount}>
              <MenuSelect
                selectButtonStyles={{
                  border: theme.selects.menu.border,
                  color: theme.selects.menu.color,
                }}
                title={user.companyName}
                options={[
                  {
                    title: { path: 'header.logout' },
                    onClick: () => {
                      userVar({});
                      deleteCookie(AUTH_TOKEN);
                      setShowActions(!showActions);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  return null;
};

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    width: '100wh',
    height: '84px',
    padding: '0 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.header.backgroundColor,
  },
  gridItem: {
    height: '100%',
  },
  headerWrapper: {
    height: '100%',
  },
  navigationContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '21px',
  },
  titleContainer: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  userAccount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    width: '27px',
    height: '27px',
    backgroundSize: '27px 27px',
    backgroundImage: theme.header.logoImage,
  },
  logoMobile: {
    width: '20px',
    height: '20px',
    backgroundSize: '20px 20px',
    backgroundImage: theme.header.logoImage,
  },
  titleWrapper: {
    margin: '10px',
    color: theme.header.titleColor,
    fontSize: '21px',
  },
  titleWrapperMobile: {
    margin: '5px',
    color: theme.header.titleColor,
    fontSize: '18px',
  },
  closeButton: {
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 31 2)' stroke='white' stroke-width='2'/%3E%3Cline x1='2.70711' y1='1.29289' x2='31.7071' y2='30.2929' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")`,
    width: '33px',
    height: '33px',
    backgroundSize: '33 33',
  },
  stepsContainer: {
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    fontSize: '16px',
    color: '#fff',
  },
  unActiveStepsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    justifyContent: 'center',
    fontSize: '16px',
    color: 'rgba(256, 256, 256, 0.5)',
  },
  unActiveStepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    justifyContent: 'flex-start',
    fontSize: '15px',
    color: 'rgba(256, 256, 256, 0.2)',
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    justifyContent: 'flex-start',
    fontSize: '15px',
    color: 'rgba(256, 256, 256, 0.4)',
  },
  activeNavigationContainer: {
    height: '100%',
    background: theme.header.tabs.activeBackgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '21px',
    color: theme.header.tabs.activeColor,
  },
  unActiveNavigationContainer: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '21px',
    color: theme.header.tabs.unActiveColor,
  },
}));
